import { useEffect, useState } from "react"
import Table from "../../components/Table/Table"
import { Transaction } from "../../models/transactions.model"
import { Box, Typography } from "@mui/material"
import {
  GridCallbackDetails,
  GridColDef,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid"
import TransactionDetail from "./TransactionDetail"
import { ListingRowMenu } from "../../components/ListingRowMenu.tsx"
import { allowedOnlyBankAccountsRoles } from "../../constants/data"
import { selectLogin } from "../../slices/loginSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { ReconcileIconButton, ReferenceNoCell } from "./styles"
import { formatPriceWithRupeeSymbol } from "../../utils/price-formatter"
import {
  getTransactionDateDisplayValue,
  getTransactionTypeDisplayValue,
  shouldShowReconcileButton,
} from "../../utils/transactionHelpers"
import { MarkedStatusChip } from "./MarkedStatusChip"
import ReconciliationDrawer from "../ReconciliaionDrawer"
import {
  unCategoriseTransactionRequest,
  unMatchTransactionRequest,
} from "../../slices/reconcilationSlice"
import TransactionAction from "./TransactionAction"

interface TransactionTableProps {
  transactions: Transaction[]
  isLoading: boolean
  pageInfo: any
  paginationModel: any
  setPaginationModel: React.Dispatch<React.SetStateAction<any>>
  sortModel?: GridSortModel | undefined
  onSortModelChange?:
    | ((model: GridSortModel, details: GridCallbackDetails) => void)
    | undefined
}

export const TransactionTable = ({
  transactions,
  isLoading,
  pageInfo,
  paginationModel,
  setPaginationModel,
  sortModel,
  onSortModelChange,
}: TransactionTableProps) => {
  const { role } = useAppSelector(selectLogin)
  const dispatch = useAppDispatch()
  const [rowCountState, setRowCountState] = useState(
    pageInfo?.totalRowCount || 0,
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const [reconcileDialogOpen, setReconcileDialogOpen] = useState(false)

  const [rowIndex, setRowIndex] = useState<number>(0)

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState,
    )
  }, [pageInfo?.totalRowCount, setRowCountState])

  const handleDialogOpen = (index: number) => {
    setRowIndex(index)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleReconcileDialogOpen = (index: number) => {
    setRowIndex(index)
    setReconcileDialogOpen(true)
  }

  const handleReconcileDialogClose = () => {
    setReconcileDialogOpen(false)
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, sortable: false },
    { field: "date", headerName: "Date", width: 180, sortable: true },
    {
      field: "referenceNo",
      headerName: "Reference No",
      width: 120,
      sortable: false,
      renderCell: ({ value, row }) => {
        return (
          <ReferenceNoCell onClick={() => handleDialogOpen(row.rowIndex)}>
            {value || "--"}
          </ReferenceNoCell>
        )
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      sortable: true,
      valueFormatter: ({ value }) => {
        return formatPriceWithRupeeSymbol(value)
      },
    },
    // {
    //   field: "tags",
    //   headerName: "Tags",
    //   width: 80,
    //   sortable: false,
    //   renderCell: ({ row }) => {
    //     return (
    //       <Chip
    //         label="SD"
    //         color="info"
    //         size="small"
    //         sx={{ color: "white", fontSize: "10px", borderRadius: "4px" }}
    //         deleteIcon={<ExpandMoreIcon color="info" />}
    //         onDelete={() => handleDialogOpen(row.rowIndex)}
    //       />
    //     )
    //   },
    // },
    {
      field: "type",
      headerName: "Type",
      width: 80,
      sortable: false,
      valueFormatter: ({ value }) => {
        return getTransactionTypeDisplayValue(value)
      },
    },
    {
      field: "accountName",
      headerName: "Bank Name",
      width: 180,
      sortable: false,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return allowedOnlyBankAccountsRoles.includes(role) ? (
          <ListingRowMenu
            name="invoice"
            options={
              row?.settlements?.invoices?.length === 0
                ? []
                : row?.settlements?.invoices.map(
                    (invoice: {
                      invoice_url: string
                      invoice_number: string
                    }) => {
                      return {
                        value: invoice.invoice_url,
                        name: invoice.invoice_number,
                      }
                    },
                  )
            }
          />
        ) : (
          "NA"
        )
      },
    },
    {
      field: "payee",
      headerName: "Payee",
      width: 150,
      sortable: false,
    },
    {
      field: "isMarked",
      headerName: "Marked",
      type: "boolean",
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <MarkedStatusChip row={row} />
      },
    },
    {
      field: "reconcile",
      headerName: "Actions",
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        if (row.isCategorised || row.isReconciled || row.isMarked) {
          return (
            <TransactionAction
              title="Uncategorise"
              confirmText="Are you sure you want to uncategorise this transaction?"
              id={row.id}
              handleTransactionRequest={() =>
                dispatch(unCategoriseTransactionRequest(row.id))
              }
            />
          )
        }

        if (row.isMatched) {
          return (
            <TransactionAction
              title="Uncategorise" // using uncategorise for unmatch too
              confirmText="Are you sure you want to uncategorise this transaction?"
              id={row.id}
              handleTransactionRequest={() =>
                dispatch(unMatchTransactionRequest(row.id))
              }
            />
          )
        }

        return shouldShowReconcileButton(row) ? (
          <ReconcileIconButton
            onClick={() => handleReconcileDialogOpen(row.rowIndex)}
          >
            Reconcile
          </ReconcileIconButton>
        ) : null
      },
    },
  ]

  const rows: GridRowsProp = transactions.map((transaction, index) => ({
    rowIndex: index,
    id: transaction.id,
    date: transaction.date
      ? getTransactionDateDisplayValue(transaction.date)
      : "",
    description: transaction.description,
    amount: transaction.amount,
    type: transaction.type,
    referenceNo: transaction.referenceNo,
    valueDate: transaction.valueDate
      ? getTransactionDateDisplayValue(transaction.valueDate)
      : "",
    branch: transaction.branch,
    balance: transaction.balance,
    isCategorised: transaction.isCategorised,
    isMarked: transaction.isMarked,
    isMatched: transaction.isMatched,
    isReconciled: transaction.isReconciled,
    isSynced: transaction.isSynced,
    invoiceNumber: transaction.invoiceNumber,
    paymentId: transaction.paymentId,
    paymentURL: transaction.paymentURL,
    invoiceURL: transaction.invoiceURL,
    payee: transaction.payee,
    accountName: transaction.accountName,
    settlements: transaction.settlements,
  }))

  const columnVisibilityModel = {
    id: false,
    branch: false,
    balance: false,
    valueDate: false,
    paymentId: false,
  }

  return (
    <>
      {rows.length === 0 ? (
        <Box sx={{ textAlign: "center", marginTop: 20 }}>
          <Typography variant="body1">No data available</Typography>
        </Box>
      ) : (
        <Table
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={rowCountState}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          disableColumnFilter
          columnVisibilityModel={columnVisibilityModel}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          height="calc(100vh - 210px)"
        />
      )}
      {dialogOpen && (
        <TransactionDetail
          open={dialogOpen}
          onClose={handleDialogClose}
          transactionData={transactions[rowIndex]}
        />
      )}
      {reconcileDialogOpen && (
        <ReconciliationDrawer
          open={reconcileDialogOpen}
          onClose={handleReconcileDialogClose}
          transactionData={transactions[rowIndex]}
        />
      )}
    </>
  )
}
