import { ChipOwnProps } from "@mui/material"

export const timeSlotArray = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "24:00",
]

export const durationArray = [
  { title: "30 minutes", value: "30" },
  { title: "60 minutes", value: "60" },
  { title: "90 minutes", value: "90" },
  { title: "120 minutes", value: "120" },
  { title: "150 minutes", value: "150" },
  { title: "180 minutes", value: "180" },
]

export enum DaypassType {
  DAYPASS = "daypass",
  BULKDAYPASS = "bulk_daypass",
}

export enum VerificationLogStatus {
  VALID = "valid",
  INVALID = "invalid",
  VERIFY = "verify",
  ACCEPT = "accept",
  REJECT = "reject",
  FAILED = "failed",
}

export enum DaypassBookingStatus {
  AVAILABLE = "available",
  USED = "used",
  EXPIRED = "expired",
}

export enum BookingRefType {
  NONE = "none",
  ANDROID = "android-app",
  IOS = "ios-app",
  SYSTEM = "system",
  WEB = "web",
}

export enum PaymentStatus {
  PENDING = "pending",
  PAID = "paid",
  FAILED = "failed",
  CANCELLED = "cancelled",
  REFUNDED = "refunded",
}

export enum UserStatus {
  CREATED = "created",
  INVITED = "invited",
  ACTIVE = "active",
  INACTIVE = "inactive",
  SUSPENDED = "suspended",
  DELETED = "deleted",
  PARTIALLY_REGISTERED = "partially_registered",
}

export enum MeetingRoomType {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum VerificationLogType {
  ENTRY = "entry",
  EXIT = "exit",
  RE_ENTRY = "re-entry",
}

export enum VerificationLogPurpose {
  MEETING_ROOM = "meeting_room",
  DAY_PASS = "day_pass",
  OTHER = "other",
}

export enum MeetingRoomBookingStatus {
  ACTIVE = "active",
  EXPIRED = "expired",
  INACTIVE = "expired",
}

export enum UserRole {
  ADMIN = "admin",
  AUDITOR = "auditor",
  ACCOUNT = "account",
  ACCOUNT_ADMIN = "account_admin",
  ALTS_ACCOUNT_ADMIN = "alts_account_admin",
  ALTS_SALES = "alts_sales",
}

export enum MarkedStatus {
  MARKED = "True",
  UNMARKED = "False",
  PENDING = "Pending",
}

export enum TransactionType {
  DEBIT = "D",
  CREDIT = "C",
}

export const operators: {
  option: string
  value: string
}[] = [
  {
    option: "",
    value: "",
  },
  {
    option: "Equal ( = )",
    value: "eq",
  },
  {
    option: "Greater than Equal ( >= )",
    value: "gte",
  },
  {
    option: "Less than Equal ( <= )",
    value: "lte",
  },
  {
    option: "Less Than ( < )",
    value: "lt",
  },
  {
    option: "Greater Than ( > )",
    value: "gt",
  },
]

export const allowedOnlyTrasactionsRoles: string[] = [
  UserRole.ACCOUNT,
  UserRole.ACCOUNT_ADMIN,
  UserRole.ALTS_ACCOUNT_ADMIN,
  UserRole.ALTS_SALES,
]

export const allowedOnlyBankAccountsRoles: string[] = [
  UserRole.ADMIN,
  UserRole.ACCOUNT_ADMIN,
]

export const PaymentStatusColors: Record<PaymentStatus, ChipOwnProps["color"]> =
  {
    [PaymentStatus.PENDING]: "primary",
    [PaymentStatus.PAID]: "success",
    [PaymentStatus.FAILED]: "error",
    [PaymentStatus.CANCELLED]: "warning",
    [PaymentStatus.REFUNDED]: "info",
  }

export enum DateFilterValues {
  TODAY = "today",
  TOMORROW = "tomorrow",
  YESTERDAY = "yesterday",
  WEEK = "week",
  MONTH = "month",
  LAST_MONTH = "last month",
  QUARTER = "quarter",
  LAST_QUARTER = "last quarter",
  SEVEN_DAYS = "7 days",
  THRITY_DAYS = "30 days",
  LAST_NINTY_DAYS = "last 90 days",
  CUSTOM = "custom",
}

export const DateFilterLabels = {
  [DateFilterValues.TODAY]: "Today",
  [DateFilterValues.TOMORROW]: "Tomorrow",
  [DateFilterValues.YESTERDAY]: "Yesterday",
  [DateFilterValues.WEEK]: "This Week",
  [DateFilterValues.MONTH]: "This Month",
  [DateFilterValues.LAST_MONTH]: "Last Month",
  [DateFilterValues.QUARTER]: "This Quarter",
  [DateFilterValues.LAST_QUARTER]: "Last Quarter",
  [DateFilterValues.SEVEN_DAYS]: "Last 7 days",
  [DateFilterValues.THRITY_DAYS]: "Last 30 days",
  [DateFilterValues.LAST_NINTY_DAYS]: "Last 90 days",
  [DateFilterValues.CUSTOM]: "Custom",
}
